<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <StoresList />
  </Layout>
</template>

<script>
import Layout from '@/views/Layout'
import StoresList from '@/components/Stores/StoresList'

export default {
  name: 'StoresIndexView',
  components: {
    Layout,
    StoresList
  },
  computed: {
    breadcrumbs: () => [
      {
        text: 'Início',
        to: { name: 'HomeIndex' }
      },
      {
        text: 'Cadastros',
        to: { name: 'RegistersIndex' }
      },
      {
        text: 'Lojas',
        to: { name: 'StoresIndex' },
        active: true
      }
    ]
  }
}
</script>
