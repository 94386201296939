<template>
  <div>
    <b-button-toolbar key-nav class="mb-3">
      <b-button variant="primary" size="sm" :to="{ name: 'StoreRegister' }">
        <span class="fas fa-plus mr-1" /> Cadastrar Loja
      </b-button>
    </b-button-toolbar>

    <b-alert v-if="error" show variant="danger">
      <span class="fas fa-exclamation-triangle" /> {{ error }}
    </b-alert>

    <b-table
      v-else
      ref="storesList"
      :fields="fields"
      :items="provider"
      striped
      hover
      show-empty
      :busy="isBusy"
    >
      <content-placeholders slot="busy" :lines="5" />
      <div slot="empty" class="text-center">Nenhuma loja cadastrada</div>
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="getColumnStyle(field.key)"
        />
      </template>
      <template #cell(status)="{ value: status }">
        <Status :value="status" />
      </template>
      <template #cell(actions)="{ item }">
        <BaseListActions
          :item="item"
          @edit="onEdit"
          @remove="onRemove"
          @updateStatus="onUpdateStatus"
        >
          <b-button
            v-if="!isCurrentUserMainStore(item)"
            size="sm"
            class="d-flex align-items-center justify-content-center"
            variant="info"
            @click="onSetMain(item)"
          >
            <span class="fas fa-star mr-1" />
            <span class="d-none d-md-block d-lg-block">Principal</span>
          </b-button>
        </BaseListActions>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import services from '@/services'
import Status from '@/components/Status'
import BaseListActions from '@/components/BaseListActions'
import withStatusAction from '@/mixins/withStatusAction'
import { parseHTTPError } from '@/helpers/errors'

export default {
  name: 'StoresList',
  components: {
    Status,
    BaseListActions
  },
  mixins: [withStatusAction(services.stores.updateStore)],
  data() {
    return {
      isBusy: false,
      error: null
    }
  },
  computed: {
    ...mapGetters('user', ['currentUserMainStore']),
    fields: () => [
      {
        key: 'name',
        label: 'Nome'
      },
      {
        key: 'status',
        label: 'Status'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  },
  methods: {
    async provider() {
      this.isBusy = true
      try {
        return await services.stores.fetchStores()
      } catch (error) {
        this.error = parseHTTPError(error)
        return []
      } finally {
        this.isBusy = false
      }
    },
    onEdit(store) {
      const { id } = store

      this.$router.push({
        name: 'StoreRegister',
        params: { id }
      })
    },
    async onRemove(store) {
      const message = `Deseja realmente remover a loja ${store.name}?`
      const result = await this.$bvModal.msgBoxConfirm(message)

      if (result) {
        await services.stores.removeStore(store.id)
        this.$refs.storesList.refresh()
        this.$root.$toast.success('Loja removida com sucesso')
      }
    },
    async onUpdateStatus(store) {
      await this.toggleStatus(store)
      this.$refs.storesList.refresh()
    },
    async onSetMain(store) {
      const message = `Deseja realmente definir ${store.name} como principal?`
      const result = await this.$bvModal.msgBoxConfirm(message)

      if (result) {
        await services.stores.setMain(store)
        this.$refs.storesList.refresh()
        this.$root.$toast.success(`${store.name} definida como loja principal`)
      }
    },
    getColumnStyle(field) {
      return { width: field === 'actions' ? '1%' : '' }
    },
    isCurrentUserMainStore(store) {
      return store.id === this.currentUserMainStore?.id
    }
  }
}
</script>
